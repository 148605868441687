import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  alpha,
  Box,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { compact, flatten, isEmpty, isNil, sortBy, uniq } from 'lodash-es';
import React, { useCallback, useMemo, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import useDirectUpload from './useDirectUpload';
import MultiFileSummaries from '@/components/elements/upload/fileSummary/MultiFileSummaries';
import SingleFileSummary from '@/components/elements/upload/fileSummary/SingleFileSummary';
import FileThumbnailIcon from '@/components/elements/upload/FileThumbnailIcon';
import { DirectUpload, FileFieldsFragment } from '@/types/gqlTypes';
import { ensureArray } from '@/utils/arrays';

const DEFAULT_MAX_BYTES = 3000000;
const IMAGE_FILE_TYPES = ['.png', '.jpg', '.jpeg', '.gif'];

const getFileTypesFromAccept = (accept: Accept) => {
  let arr = sortBy(
    compact(uniq(flatten(Object.values(accept)))).map((e) =>
      e.toUpperCase().replace(/\.(.*)/, '$1')
    )
  );

  if (arr.length === 2) arr = [arr.join(' or ')];
  if (arr.length > 2) arr = [...arr.slice(0, -2), arr.slice(-2).join(' or ')];

  return arr.join(', ');
};

const getReadableSize = (maxSize: number) =>
  `${(maxSize / 1000000).toFixed(1)}MB`;

const isFileFieldsFragment = (
  value: string | FileFieldsFragment
): value is FileFieldsFragment =>
  !isNil(value) && typeof value === 'object' && value.__typename === 'File';

type ExistingFileType = string | FileFieldsFragment;
export interface UploaderProps<Multiple extends boolean> {
  id: string;
  multiple?: Multiple;
  files?: Multiple extends true ? ExistingFileType[] : ExistingFileType;
  onChange?: (
    files: Multiple extends true
      ? ExistingFileType[]
      : ExistingFileType | undefined
  ) => void;
  onUpload?: (
    uploads: Multiple extends true ? DirectUpload[] : DirectUpload | undefined,
    files: Multiple extends true ? File[] : File
  ) => any | Promise<any>;
  accept?: Accept;
  image?: boolean;
  maxSize?: number;
  ariaLabel?: string | null;
  disabled?: boolean;
}

const Uploader = <Multiple extends boolean>({
  id,
  multiple = false as Multiple,
  files,
  onChange,
  onUpload,
  accept: acceptProp,
  image: isImage = false,
  maxSize = DEFAULT_MAX_BYTES,
  ariaLabel,
  disabled,
}: UploaderProps<Multiple>) => {
  // The uploader accepts a `files` argument which can contain either:
  // - a STRING which points at a blob ID of a file that has been uploaded within this session, or
  // - a FileFieldsFragment record which points at a file record in our database, uploaded during a previous session.
  // It needs to accept both because this can be a controlled component, so the parent may be keeping track of both previous and current upload state.
  // `existingFiles` filters the list to only those files that were uploaded some previous time, so we can render them.
  // The files uploaded during this session, we render this component's internal state, `currentFiles`.
  const existingFiles: FileFieldsFragment[] = useMemo(() => {
    return ensureArray(files).filter(
      // Filter out files from the input that are just blob IDs.
      // These should also be reflected in the currentFiles internal state object.
      (f) => isFileFieldsFragment(f)
    );
  }, [files]);

  // The currentFiles are File objects (https://developer.mozilla.org/en-US/docs/Web/API/File)
  // returned by the react-dropzone callbacks. These refer to files we received in the uploader, on this session.
  // (as opposed to FileFieldsFragments, which correspond to file records in our DB that were previously uploaded)
  const [currentFiles, setCurrentFiles] = useState<File[]>([]);
  const [currentUploads, setCurrentUploads] = useState<DirectUpload[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const [uploadFile] = useDirectUpload();

  const uniqueNameValidator = useCallback(
    (file: File) => {
      const existingNames = [
        // Check both current-session and previously-existing files to make sure we're permitting upload of a duplicate name
        ...existingFiles.map((f) => f.name),
        ...currentFiles.map((f) => f.name),
      ];

      if (existingNames.includes(file.name)) {
        return {
          code: 'name-not-unique',
          message: `File name is not unique`,
        };
      }

      return null;
    },
    [existingFiles, currentFiles]
  );

  const onChangeMultiple = useMemo(
    () =>
      multiple
        ? (onChange as ((files: ExistingFileType[]) => void) | undefined)
        : undefined,
    [multiple, onChange]
  );
  const onChangeSingle = useMemo(
    () =>
      multiple
        ? undefined
        : (onChange as ((file?: ExistingFileType) => void) | undefined),
    [multiple, onChange]
  );
  const onUploadMultiple = useMemo(
    () =>
      multiple
        ? (onUpload as
            | ((uploads: DirectUpload[], files: File[]) => any)
            | undefined)
        : undefined,
    [multiple, onUpload]
  );
  const onUploadSingle = useMemo(
    () =>
      multiple
        ? undefined
        : (onUpload as
            | ((upload?: DirectUpload, file?: File) => any)
            | undefined),
    [multiple, onUpload]
  );

  const uploadAndCreate = useCallback(
    (acceptedFiles: File[]) => {
      setLoading(true);
      const newFiles = [...currentFiles, ...acceptedFiles];

      Promise.all(acceptedFiles.map((f) => uploadFile(f)))
        .then((responses) => {
          const newUploads = [...currentUploads, ...responses];
          setCurrentFiles(newFiles);
          setCurrentUploads(newUploads);

          if (multiple) {
            if (onChangeMultiple) {
              onChangeMultiple([
                ...existingFiles,
                ...newUploads.map((u) => u.signedBlobId),
              ]);
            }
            if (onUploadMultiple) {
              onUploadMultiple(newUploads, newFiles);
            }
          } else {
            const singleUpload = newUploads[0];
            const singleFile = newFiles[0];

            if (onChangeSingle && singleUpload) {
              onChangeSingle(singleUpload.signedBlobId);
            }
            if (onUploadSingle && singleFile && singleUpload) {
              onUploadSingle(singleUpload, singleFile);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrors([error.message]);
        });
    },
    [
      currentFiles,
      uploadFile,
      currentUploads,
      multiple,
      onChangeMultiple,
      onUploadMultiple,
      existingFiles,
      onChangeSingle,
      onUploadSingle,
    ]
  );

  const accept = useMemo(() => {
    const base: Accept = {
      ...(isImage ? { 'image/*': IMAGE_FILE_TYPES } : {}),
      ...acceptProp,
    };

    return isEmpty(base) ? undefined : base;
  }, [isImage, acceptProp]);

  const {
    rootRef: inputRef,
    getRootProps,
    isDragActive,
    getInputProps,
    open,
  } = useDropzone({
    multiple,
    accept,
    maxSize,
    noClick: true,
    disabled,
    onDropAccepted: uploadAndCreate,
    onDropRejected: (fileRejections) => {
      const errors = fileRejections.flatMap((file) =>
        file.errors.map((err) => {
          const fileName = file.file.name;
          if (err.code === 'file-too-large') {
            return `${fileName} is too large. File size must be under ${getReadableSize(maxSize)}`;
          } else if (err.code === 'file-invalid-type') {
            return `${fileName} is an unsupported file type. ${
              accept
                ? `Supported file types are: ${getFileTypesFromAccept(accept)}`
                : ''
            }`;
          } else {
            return `${fileName} Error: ${err.message || err.code}`;
          }
        })
      );
      setErrors(errors);
    },
    onDrop: () => setErrors([]),
    validator: uniqueNameValidator,
  });

  const removeFile = useCallback(
    (file: File | FileFieldsFragment) => {
      const newFiles = currentFiles.filter((f) => f !== file);
      const newUploads = currentUploads.filter(
        // This is probably not ideal, but we do enforce file name uniqueness, so it should work
        (upload) => upload.filename !== file.name
      );
      setCurrentFiles(newFiles);
      setCurrentUploads(newUploads);

      if (onChangeMultiple) {
        onChangeMultiple([
          ...existingFiles.filter((f) => f !== file),
          ...newUploads.map((u) => u.signedBlobId),
        ]);
      }
      if (onUploadMultiple) {
        onUploadMultiple(newUploads, newFiles);
      }

      if (onChangeSingle) {
        onChangeSingle(undefined);
      }
      if (onUploadSingle) {
        onUploadSingle(undefined, undefined);
      }
      // favor a timeout here in this callback, rather than a useEffect, to avoid hijacking the focus on pageload
      setTimeout(() => inputRef.current?.focus(), 100);
    },
    [
      currentFiles,
      currentUploads,
      existingFiles,
      onChangeMultiple,
      onChangeSingle,
      onUploadMultiple,
      onUploadSingle,
      inputRef,
    ]
  );

  const showFileList = useMemo(
    () => multiple && (currentFiles.length > 0 || existingFiles.length > 0),
    [currentFiles.length, existingFiles.length, multiple]
  );

  return (
    <Stack>
      <Box
        sx={({ palette, shape }) => ({
          minHeight: '150px',
          transition: 'background 300ms',
          borderRadius: `${shape.borderRadius}px`,
          border: `1px dashed ${palette.divider}`,
          // Adjust bottom border if file list is showing (only for multi)
          ...(showFileList && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottom: 0,
          }),
          backgroundColor: isDragActive
            ? alpha(palette.primary.light, 0.12)
            : palette.background.paper,
          overflow: 'hidden',
        })}
        {...getRootProps()}
        id={id}
      >
        <input
          {...getInputProps()}
          tabIndex={0}
          aria-label={ariaLabel ? ariaLabel : 'Upload file'}
        />
        <Stack
          spacing={1}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '150px',
            textAlign: 'center',
            p: 2,
          }}
        >
          {/* If currently uploading (whether multi or single), show "Uploading" and hide the click-to-upload link */}
          {loading ? (
            <Box>
              <Typography variant='subtitle1' color='inherit' gutterBottom>
                Uploading
              </Typography>
              <LinearProgress variant='indeterminate' />
            </Box>
          ) : (
            <Box aria-live='polite'>
              {/* If multi-upload, always show the click-to-upload link. If not, only show it when there is no uploaded file yet. */}
              {(multiple ||
                (currentFiles.length === 0 && existingFiles.length === 0)) && (
                <>
                  <FileThumbnailIcon IconComponent={UploadFileIcon} />

                  <Typography variant='subtitle1' color='inherit'>
                    <Link onClick={open} variant='inherit'>
                      Click to upload
                    </Link>{' '}
                    or drag and drop
                  </Typography>
                  <Typography
                    variant='body2'
                    color='grayscale.main'
                    sx={{ mt: 1 }}
                  >
                    {accept ? getFileTypesFromAccept(accept) : 'Any file type'}{' '}
                    (max. {getReadableSize(maxSize)})
                  </Typography>
                </>
              )}
              {/* If it's a single-file upload, render the single summary thumbnail in the input, above the error */}
              {!multiple && (
                <SingleFileSummary
                  savedFile={existingFiles[0]}
                  unsavedFile={currentFiles[0]}
                  onRemove={removeFile}
                />
              )}
              {/* Upload errors */}
              {errors?.map((error) => (
                <Typography
                  key={error}
                  variant='subtitle1'
                  color='error'
                  sx={{ mt: 2 }}
                  aria-live='polite'
                >
                  {error}
                </Typography>
              ))}
            </Box>
          )}
        </Stack>
      </Box>
      {/* If it's a multi-file upload, render the summary rows below the input */}
      {showFileList && (
        <MultiFileSummaries
          savedFiles={existingFiles}
          unsavedFiles={currentFiles}
          onRemove={removeFile}
        />
      )}
    </Stack>
  );
};

export default Uploader;
