import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { ColumnDef } from '@/components/elements/table/types';
import TitleCard from '@/components/elements/TitleCard';
import NotFound from '@/components/pages/NotFound';
import GenericTableWithData from '@/modules/dataFetching/components/GenericTableWithData';
import useEnrollmentDashboardContext from '@/modules/enrollment/hooks/useEnrollmentDashboardContext';
import { useViewEditRecordDialogs } from '@/modules/form/hooks/useViewEditRecordDialogs';
import HmisEnum from '@/modules/hmis/components/HmisEnum';
import {
  eventReferralResult,
  parseAndFormatDate,
} from '@/modules/hmis/hmisUtil';
import { cache } from '@/providers/apolloClient';
import { HmisEnums } from '@/types/gqlEnums';
import {
  DataCollectionFeatureRole,
  DeleteCeEventDocument,
  EventFieldsFragment,
  GetEnrollmentEventsDocument,
  GetEnrollmentEventsQuery,
  GetEnrollmentEventsQueryVariables,
  RecordFormRole,
} from '@/types/gqlTypes';

const COLUMNS: ColumnDef<EventFieldsFragment>[] = [
  {
    header: 'Event Date',
    key: 'date',
    render: (e: EventFieldsFragment) => parseAndFormatDate(e.eventDate),
    sticky: 'left',
  },
  {
    header: 'Event Type',
    key: 'type',
    render: (e: EventFieldsFragment) => (
      <HmisEnum value={e.event} enumMap={HmisEnums.EventType} />
    ),
  },
  {
    header: 'Referral Result',
    key: 'referralResult',
    render: (e: EventFieldsFragment) => eventReferralResult(e),
  },
];

const EnrollmentCeEventsPage = () => {
  const { enrollment, getEnrollmentFeature } = useEnrollmentDashboardContext();
  const enrollmentId = enrollment?.id;
  const clientId = enrollment?.client.id;

  const evictCache = useCallback(() => {
    cache.evict({
      id: `Enrollment:${enrollmentId}`,
      fieldName: 'events',
    });
  }, [enrollmentId]);

  const localConstants = useMemo(
    () => ({
      entryDate: enrollment?.entryDate,
      exitDate: enrollment?.exitDate,
    }),
    [enrollment]
  );

  const canEditCeEvents = enrollment?.access?.canEditEnrollments || false;

  const { onSelectRecord, viewRecordDialog, editRecordDialog, openFormDialog } =
    useViewEditRecordDialogs({
      variant: canEditCeEvents ? 'view_and_edit' : 'view_only',
      inputVariables: { enrollmentId },
      formRole: RecordFormRole.CeEvent,
      recordName: 'CE Event',
      evictCache,
      deleteRecordDocument: DeleteCeEventDocument,
      deleteRecordIdPath: 'deleteCeEvent.ceEvent.id',
      maxWidth: 'sm',
      localConstants,
      projectId: enrollment?.project.id,
    });

  const ceEventFeature = getEnrollmentFeature(
    DataCollectionFeatureRole.CeEvent
  );

  if (!enrollment || !enrollmentId || !clientId || !ceEventFeature)
    return <NotFound />;

  return (
    <>
      <TitleCard
        title='Coordinated Entry Events'
        headerVariant='border'
        headerComponent='h1'
        actions={
          canEditCeEvents &&
          !ceEventFeature.legacy && (
            <Button
              onClick={openFormDialog}
              variant='outlined'
              startIcon={<AddIcon fontSize='small' />}
            >
              Add Coordinated Entry Event
            </Button>
          )
        }
      >
        <GenericTableWithData<
          GetEnrollmentEventsQuery,
          GetEnrollmentEventsQueryVariables,
          EventFieldsFragment
        >
          queryVariables={{ id: enrollmentId }}
          queryDocument={GetEnrollmentEventsDocument}
          columns={COLUMNS}
          rowActionTitle='View CE Event'
          rowName={(row) =>
            `${HmisEnums.EventType[row.event]} on ${parseAndFormatDate(row.eventDate)}`
          }
          handleRowClick={onSelectRecord}
          pagePath='enrollment.events'
          noData='No events'
        />
      </TitleCard>
      {viewRecordDialog()}
      {editRecordDialog()}
    </>
  );
};

export default EnrollmentCeEventsPage;
